import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import LayoutSub from "../../components/layoutSub";
import SEO from "../../components/seo";


function Therapy({  location, pageContext }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle="Our Therapy"
  const description="We will be able to assist you with lower back pains, neck and shoulder pain, fatigue etc.  We offer Osteopathic Manipulative Therapy."

  return (
    <LayoutSub crumbs={crumbs} lang='en' pathname={pathname}>
      <SEO
        pagetitle={pagetitle}
        description={description}
        pageurl={pathname}
        lang="en"
      />

      <section>

        <h1 className="heading-b my-2">Our Therapy</h1>


        <p>We offer&nbsp;
          <span className="marker">
          Osteopathic Manipulative Therapy
          </span>.
          Osteopathy was founded in the late 1800s by physician and surgeon Andrew Taylor Still in Kirksville, Missouri.
        </p>

        <h2 className="heading-b mt-7 mx-1 ">What is the difference between our therapy and the others?</h2>

        <p>While we focus on your joints, muscles, fascia and spine, our treatment aims to positively affect the systems such as: </p>

        <ul className="list-disc">
          <li><span className="marker">nervous</span>&nbsp;system</li>
          <li><span className="marker">circulatory</span>&nbsp;system</li>
          <li><span className="marker">lymphatic</span>&nbsp;system</li>
          <li><span className="marker">respiratory</span>&nbsp;system</li>
          <li><span className="marker">gastrointestinal</span>&nbsp;system</li>
        </ul>

        <p>and so on.&nbsp;&nbsp;One of the distinguishing features of Osteopathic Manipulative Therapy is a holistic, whole-body approach.</p>


        <p>Another feature is<span className="marker">&nbsp;a complete structural functional evaluation of the musculoskeletal system.</span></p>

        <p>For example, if your sternum or breastbone, which is a long flat bone located in the central part of the chest, or one of the ribs cannot move the way they should be, it&apos;s having a bad effect on your breathing.   Osteopathic Manipulative Therapy can improve shallow breathing which is not got well by a treatment on only spine.</p>

        <p>And osteopathy is unique having lots of treatment techniques.
        </p>

        <p>For example, think about your muscle is tight and rigit.</p>
        <ul className="list-decimal">
          <li>Your muscle is loosened with stretching.</li>
          <li>Your muscle is not loosened and just painful with stretching.</li>
        </ul>

        <div className="overflow-hidden" >
        <StaticImage
          src="../../images/inflexible-body.png"
          alt="Your muscle is not loosened and just painful with stretching."
          placeholder="blurred"
          layout="constrained"
          width={240}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />

        <p>
          In above case 2, we gently position your body where your muscle and tissues is relaxed and achieve release or muscle spasm.  Your muscle will not be relaxed with massages or stretching.</p>

        <p>We choose the most appropriate technique  which is effective to release your muscle and tissue spasm.</p>

        </div>


        <p>Osteopathy is popular in US and Europe.  Every day 30,000 people in the UK see an osteopath. </p>


        <h2 className="heading-b mt-7 mx-1 ">A suitable treatment plan to meet your needs.</h2>


        <p className="font-bold">&ldquo; My shoulder is so stiff and when it&apos;s getting bad I feel neck pain and sometimes I have headache.&rdquo;</p>


        <p>What are possible causes of this condition?</p>

        <ul className="list-disc">
          <li><span className="marker">The misalignment of the pelvis or spine</span></li>
          <li><span className="marker">Muscle imbalances</span> occur because the muscles on one side is too tight or too weak.</li>
          <li><span className="marker">Mobility of shoulder blades or collarbone</span> is restricted.</li>
          <li><span className="marker">Lack of flexibility in the pelvis</span></li>
          <li><span className="marker">The autonomic nerve</span> is disturbed.</li>
          <li>The movements and quality of <span className="marker">internal organs are restricted.</span></li>
          <li>A subtle rhythmic movement that is present in the body, known as the <span className="marker">&lsquo;Cranial Rhythm&rsquo;</span> is restricted.</li>
        </ul>


        <p>and so on.</p>

        <p>It is not uncommon for multiple causes being linked to the pain.</p>

        <p>First, we listen and ask questions about your problem, your general health, etc. and record this in your case notes. Then we physically examine you properly. We may ask you to make simple movements and stretches to observe your posture and mobility.</p>

        <p>Considering the root cause of your symptom, we make a treatment plan for you.</p>

        <h2 className="heading-b mt-7 mx-1 ">&ldquo;Fascia, Internal organs, Lymph and Breathing&rdquo;</h2>


        <p>We focus on <span className="marker">muscle/fascia</span>, <span className="marker">tissues and ligaments which support internal organs</span>, <span className="marker">the flow of lymph</span>, and breathing.</p>


        <h3 className="heading-l">muscle and fascia</h3>

        <p>Muscle and fascia get stiff after periods of inactivity, like long days at work behind a desk.</p>

        <p>We use Myofascial Release technique to release tight fascia.  We work through the fascial layers with firm pressure to slowly stretch the fascia or apply less pressure and guide the fascial layers with gentle movement to release the tightness.  The technique can be firm or gentle.  We use the either one which is suitable for your fascia condition. </p>

        <p>Fascia wraps not only muscles but also nerves, blood vessels and lymphatics.  Therefore, <span className="marker">Myofascial Release improves nervous system, lymphatic and blood circulation. </span></p>


        <h3 className="heading-l">tissues and ligaments which support internal organs</h3>

        <div className="overflow-hidden">
        <StaticImage
          src="../../images/stomach.png"
          alt="stomach"
          placeholder="blurred"
          layout="constrained"
          width={100}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />
        


        <p>All of your organs are suspended within the musculoskeletal system via specific fascial connections.  Tension in one area, for example in the abdomen around the organs, can be carried through to other regions of the body.  The tension around stomach might cause an upset stomach but also <span className="marker">orthopedic problems such as back pain and hip pain.</span></p>

        </div>

        <p>We use visceral manipulation technique to release restrictions of the connective tissues/fascia which support internal organs.</p>



        <h3 className="heading-l">Lymph flow</h3>

        <p>Sluggish lymph flow might cause stiffness or fatigues.  Myofascia release helps to solve a lymphatic issue, in addition we use other osteopathic techniques to increase lymphatic fluid movement.</p>

          <h3 className="heading-l">Breathing</h3>

          <p> <span className="marker">Breathing is directly related to your body&apos;s self-healing powers.</span>  There is an osteopath who says that he will treat sternum or breastbone, which is the most impact on breathing, if he could only treat one part of your body.</p>

          <p>We work on your muscle/fascia, tissues and ligaments which support internal organs and the flow of lymph thinking about better &ldquo; Breathing&rdquo; even if your pain is in legs or wrist.</p>


          <h2 className="heading-b mt-7 mx-1 ">Gentle touch and more...</h2>

          <p>We primarily use gentle techniques.  Our manipulations use of your body’s own efforts to effect change and it is to be done in the absence of pain.  You can be completely relaxed and comfortable during the session.</p>

<p>We take a holistic, whole-body approach, not just the injured or affected part.  We use osteopathic techniques to balance all the body systems,  and to help the body heal itself.</p>

    
      </section>
    </LayoutSub>
      );
}

      export default Therapy;

      export const query = graphql`
      query {
        site {
        siteMetadata {
        siteUrl
      }
    }
  }
      `